.css-levciy-MuiTablePagination-displayedRows{
 margin: 0 !important;
}

.css-pdct74-MuiTablePagination-selectLabel{
 margin: 0 !important;
}

th.header-match {
    color: #a1a5b7;
    font-weight: 600 !important;
    font-size: 12px !important;
    /* background-color: rgb(245, 248, 250) !important; */
    width: 1% !important;
    white-space: nowrap !important;
}

td.body-match {
    width: 1% !important;
    white-space: nowrap !important;
}

.up-icon {
    transform: translateY(-1px);
}
