//
// Item Hover Custom
//

.item-border-hover {
  border: 1px solid #fff;
  border-radius: 6px;

  &:hover {
    border: 1px solid #eff2f5;
    cursor: pointer;
    transition: $transition-link;
    background-color: rgba(245, 248, 250, 0.3);

    .label {
      background-color: #00a3ff !important;
      transition: $transition-link;
      color: #fff;
    }
  }

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
}

.data-item {
  cursor: pointer;
  transition: all 0.2s;

  span {
    transition: all 0.2s;
  }

  &:hover {
    background-color: darken($primary-light, 3%) !important;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.09);
    transform: translateY(-0.5rem);
  }

  &.selected {
    background-color: darken($primary-light, 6%) !important;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.09);
    transform: translateY(-0.5rem);
  }
}

.dashboard-item {
  cursor: pointer;
  transition: all 0.2s;
  background-color: #ffffff;

  .svg-icon {
    svg {
      [fill]:not(.permanent):not(g) {
        transition: fill 0.3s ease;
        fill: #ffffff;
      }
    }
  }

  .svg-icon.svg-icon-primary {
    svg {
      [fill]:not(.permanent):not(g) {
        fill: #009ef7;
      }
    }
  }

  p {
    color: #ffffff;
  }

  &:hover {
    background-color: $primary-light !important;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.09);
    transform: translateY(-0.5rem);

    .svg-icon {
      svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: #009ef7;
        }
      }
    }
  
    p {
      color: #009ef7;
    }
  }

  &.selected {
    background-color: darken($primary-light, 3%) !important;
  }
}

hr {
  background-color: $gray-500;
}
